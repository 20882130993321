import { render, staticRenderFns } from "./PenoReportDashboard.vue?vue&type=template&id=1a2e759c&scoped=true&"
import script from "./PenoReportDashboard.vue?vue&type=script&lang=js&"
export * from "./PenoReportDashboard.vue?vue&type=script&lang=js&"
import style0 from "./PenoReportDashboard.vue?vue&type=style&index=0&id=1a2e759c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a2e759c",
  null
  
)

export default component.exports