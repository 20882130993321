<template>
  <section class="peno-report-dashboard">
    <div class="peno-nav-container">
      <div class="container-fluid">
        <ul class="peno-nav">
          <li>
            <router-link
              :to="{ name: 'peno.plan_relation' }"
              :class="{ active: currentTab == 'peno.plan_relation' }"
            >
              <div class="number-container">
                <div class="number">1</div>
              </div>
              <div class="nav-text">
                <!--<div>ความเชื่อมโยงของ<br />แผนในระดับต่างๆ</div>-->
                <div>ความเชื่อมโยงของแผนในระดับต่างๆ</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'peno.current_situation' }"
              :class="{
                active: currentTab.startsWith('peno.current_situation'),
              }"
            >
              <div class="number-container">
                <div class="number">2</div>
              </div>
              <div class="nav-text">
                <!--<div>สถานการณ์ปัจจุบัน<br />ด้านพลังงาน</div>-->
                <div>สถานการณ์ปัจจุบันด้านพลังงาน</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'peno.scenarios' }"
              :class="{ active: currentTab.startsWith('peno.scenarios') }"
            >
              <div class="number-container">
                <div class="number">3</div>
              </div>
              <div class="nav-text">
                <!--<div>แนวโน้มสถานการณ์<br />ด้านพลังงานในอนาคต</div>-->
                <div>แนวโน้มสถานการณ์ด้านพลังงานในอนาคต</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'peno.targets' }"
              :class="{
                active:
                  currentTab == 'peno.targets' || currentTab == 'peno.swot',
              }"
            >
              <div class="number-container">
                <div class="number">4</div>
              </div>
              <div class="nav-text">
                <div>เป้าประสงค์พลังงาน SWOT และกลยุทธ์</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'peno.projects' }"
              :class="{ active: currentTab == 'peno.projects' }"
            >
              <div class="number-container">
                <div class="number">5</div>
              </div>
              <div class="nav-text">
                <!--<div>ออกแบบ<br />โครงการ/กิจกรรม</div>-->
                <div>ออกแบบโครงการ</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'peno.summary' }"
              :class="{ active: currentTab == 'peno.summary' }"
            >
              <div class="number-container">
                <div class="number">6</div>
              </div>
              <div class="nav-text">
                <div>ภาพรวม</div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <transition mode="out-in" name="fade" v-if="ready">
      <router-view class="animated" />
    </transition>
  </section>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  name: 'peno-report-dashboard',

  data() {
    return {
      currentTab: this.$route.name,
      ready: false,
    };
  },

  watch: {
    $route(to, from) {
      this.currentTab = to.name;
    },
  },

  created() {
    this.init();
  },

  mounted() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date(this.currentNationalPlan.end_date);
    let allow = this.isImpersonating ? false : endDate >= today;
    this.$store.commit('peno/setAllowEdit', allow);
  },

  computed: {
    ...mapGetters('auth', ['isImpersonating']),
    ...mapGetters('peno', ['allowEdit']),
    ...mapGetters(['currentNationalPlan']),
  },

  methods: {
    ...mapActions('peno', ['fetchReport']),
    init() {
      this.fetchReport().then((data) => {
        this.ready = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.peno-report-dashboard {
  /*margin-top: 1px;*/
}

.peno-nav-container {
  border-top: solid 1px rgba(0, 0, 0, 0.08);
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  /*z-index: 1;*/
  /*position: relative;*/
}

/* nav white bg that also cover top header's shadow*/
.peno-nav-container:before {
  content: '';
  background-color: white;
  position: absolute;
  height: 60px;
  width: 4000px;
  right: 0;
  z-index: 0;
}

.peno-nav {
  margin: 0 0 0 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  font-size: 18px;
  line-height: 20px;
  /*z-index: 1000;*/
}

.peno-nav .number-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.peno-nav .number {
  color: #c6b9b9;
  background-color: $light-grey-color;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  /*line-height: 1;*/
  /*vertical-align: middle;*/
}

.peno-nav a.active .number {
  color: $base-text-color;
  background-color: $brand-color-200;
}

.peno-nav li {
  float: left;
}

.peno-nav li a {
  color: $light-base-color;
  text-decoration: none;
  padding: 10px 0 10px 50px;
  background: white;
  position: relative;
  display: flex;
  float: left;
  transition: all 0.2s ease-in-out;
  /*width: 240px;*/
  width: calc(calc(100vw - 100px) / 6);
  outline: none;
}

.peno-nav li a::before,
.peno-nav li a::after {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.peno-nav li a::before {
  border-left: 30px solid $light-grey-color;
  margin-left: 2px;
}

.peno-nav li a::after {
  border-left: 30px solid white;
  transition: all 0.2s ease-in-out;
}

.peno-nav li a:hover {
  /*background: $lightest-grey-color;*/
  background: $brand-color-100;
}

.peno-nav li a:hover::after {
  border-left: 30px solid $brand-color-100;
}

.peno-nav li a.active {
  color: white;
  background-color: $brand-color;
}

/*.peno-nav li a.active::after {
  border-left: 30px solid $lighter-grey-color;
}*/
.peno-nav li a.active::before {
  border-left: 30px solid $brand-color-500;
}

.peno-nav li a.active::after {
  border-left: 30px solid $brand-color;
}

.peno-nav li:first-child a {
  padding-left: 10px;
}

.peno-nav .nav-text {
  height: 40px;
  display: flex;
  align-items: center;
}

.peno-nav .nav-text p {
  margin: 0;
}
</style>
